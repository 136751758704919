import * as React from "react"
import Layout from "../components/common/layout"
import Hero from "../components/hero"
import TextMedia from "../components/text-media"
import Title from "../components/title"
import ThreeCard from "../components/three-card"
import FourthCard from "../components/fourth-card"
import VideoBlock from "../components/video-block"
import ListLogo from "../components/list-logo"
import {  graphql } from "gatsby"
import PreFooter from "../components/pre-footer"
import { Helmet } from 'react-helmet'
// markup 
const IndexPage = ({ data }: any) => {
  const _data = data.allSampleDataJson?.nodes[0].data || []
  const dataPrefooter = _data.find((item: any) => item.block === 'prefooter')
  const dataTextMedia = _data.find((item: any) => item.block === 'text-media')
  const dataTitle = _data.find((item: any) => item.block === 'title')
  const threeCard = _data.find((item: any) => item.block === 'three-card')
  const fourthCard = _data.find((item: any) => item.block === 'fourth-card')
  const hero = _data.find((item: any) => item.block === 'hero')
  const logos = _data.find((item: any) => item.block === 'logos')
  return (
    <Layout>
      <Helmet
        title='Home page'
      />
      <Hero container={false} background="bg-yellow-blue" data={hero.data[0]}></Hero>
      <VideoBlock
        video="https://vimeo.com/93003441"
        title="Watch the 2 minute tour"
        background="bg-blue"
        thumbnail="/images/place-holder-16x9.jpg" 
      />
      <Title data={dataTitle.data[0]} background="bg-blue-bale" ></Title>
      <ThreeCard data={threeCard.data[0]} background="bg-blue-bale"></ThreeCard>
      <Title data={dataTitle.data[1]}  background="bg-chalk"></Title>
      <TextMedia data={dataTextMedia.data[0]} reverse={true}  background="bg-chalk" ></TextMedia>
      <TextMedia data={dataTextMedia.data[1]}  background="bg-chalk" ></TextMedia>
      <TextMedia data={dataTextMedia.data[2]} reverse={true}   background="bg-chalk" ></TextMedia>   
      <Title data={dataTitle.data[2]} background="bg-blue-bale"></Title>
      <ThreeCard data={threeCard.data[1]} background="bg-blue-bale"></ThreeCard>
      <Title data={dataTitle.data[3]} background="bg-white"></Title>
      <FourthCard data={fourthCard.data[0]} background="bg-white" ></FourthCard>
      <ListLogo data={logos.data[0]} background="bg-white" readMore={true}></ListLogo>
      <PreFooter data={dataPrefooter.data[0]} background="bg-blue"></PreFooter>  
    </Layout>
  )
}

// Step 2: Export a page query
export const query = graphql`
  query {
    allSampleDataJson(filter: {jsonName: {in: ["home"]}}) {
      nodes {
        jsonName
        data {
          block
          data {
            hero {
              hight_light
              content
              images {
                id
                url
                width
              }
              title
              title_top
            }
            title_data {
              title
              content
            }
            text_media {
              title
              content
              image
              video {
                thumbnail
                title
                video
              }
              links{
                id
                text
                url
              }
            }
            cards {
              id
              title
              image
              link
            }
            prefooter_data {
              content
              title
              links {
                id
                text
                url
              }
            }
            logo {
              link
              title
              images {
                id
                url
              }
            }
          }
        }
      }
    }
  }
`
export default IndexPage
